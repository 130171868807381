.satLogin {
    .card-body {
        width: 50%;
        margin: 0 auto;
    }
    h4 {
        font-family: $default-titles;
        font-size: $default-lg;
        text-align: center;
        font-weight: bold;
        color: $default-titles-primary;
    }
    h6 {
        font-family: $default-text;
        font-size: $default-md;
        text-align: center;
        color: $default-text-primary;
    }

    .form-control {
        margin-bottom: $default-xs;
        font-size: $default-md;
        font-family: $default-text;
        color: $default-titles-primary;
        border-bottom: 1px solid $default-border;
        transition: .9ms;

        &:hover {
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        &::placeholder {
            color: $default-text-secondary;
        }
    }

    .form-control:focus {
        background: none;
        border-bottom: 1px solid $default-border-selected;
        transition: .9ms;
    }

    &-link {
        display: block;
        margin-bottom: $default-lg;
        font-size: $default-md;
        font-family: $default-text;
        text-align: right;
        color: $default-info;

        &:hover {
            color: $default-info;
            text-decoration: underline;
        }
    }

    &-button {
        display: block;
        margin: 0 auto;
        font-family: $default-titles;
        font-size: $default-md;
        background: $default-button-background;
        color: $default-button-text;
        border: none;

        &:active, &:focus, &:hover {
            background: $default-button-background !important;
            border: none;
        }
    }

    &-text {
        text-align: center;
        font-size: $default-sm;
        font-family: $default-text;
        color: $default-text-secondary;
    }

    &-modal-body {
        p {
            font-size: $default-md;
            font-family: $default-text;
            color: $default-text-primary;
        }

    }

    &-modal-footer {
        button {
            margin: 0 $default-sm;
            padding: $default-sm;
            font-size: $default-md;
            font-family: $default-titles;
            font-weight: bold;
            background: $default-button-background;
            color: $default-button-text;
            border: none;
            border-radius: $default-xs;
            transition: all .5s ease-in-out;
    
            &:hover, &:active, &:focus {
                background: $default-button-backgroundHover !important;
                transition: all .5s ease-in-out;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .satLogin {
        .card-body {
            width: 100%;
            margin: 0 auto;
        }

        &-link {
            text-align: center;
        }
    }
}
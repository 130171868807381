.customerProfile {
    margin: 0 !important;
    background-color: transparent;

    &-body {
        padding: $default-md !important;
        box-shadow: none !important;
    }

    &-header {
        align-items: center;
    }

    &-title {
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-titles-primary;
    }

    &-tabs {
        border-bottom: none;
    }

    &-tab--active {
        font-size: $default-md;
        font-family: $default-text;
        background-color: $default-button-backgroundSolid !important;
        background-image: none !important;
        color: $default-button-text !important;
    }

    &-tab-pane {

        .card {
            margin: $default-md;
            border: 1px solid $default-border-selected;
            box-shadow: none;

            .form-control {
                margin-bottom: $default-xs;
                font-size: $default-md;
                font-family: $default-text;
                color: $default-titles-primary;
                border-bottom: 1px solid $default-border;
                transition: .9ms;

                &:hover {
                    border-bottom: 1px solid $default-border-selected;
                    transition: .9ms;
                }

                &::placeholder {
                    color: $default-text-secondary;
                }
            }

            .form-control:focus {
                background: none;
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            h5 {
                font-family: $default-titles;
                font-size: $default-md;
                color: $default-titles-secondary;
            }

            label, p {
                font-family: $default-text;
                font-size: $default-md;
            }
        }
    }

    &-saveBtn {
        font-family: $default-titles;
        background-color: $default-button-backgroundSolid;
        color: $default-button-text;
        border: none;

        &:active, &:focus, &:hover {
            background-color: $default-button-backgroundSolid !important;
            border: none;
        }
    }

    &-editBtn {
        font-family: $default-titles;
        background: $default-button-background;
        color: $default-button-text;
        border: none;

        &:active, &:focus, &:hover {
            background: $default-button-background !important;
            border: none;
        }
    }

    &-files {
        margin: $default-md;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .doc-showBtn {
            background-color: $default-button-backgroundSolid;
            border-color: $default-border-selected;

            &:active, &:hover, &:focus {
                background-color: $default-button-backgroundSolid !important;
                border-color: $default-border-selected !important;
            }
        }

        p {
            font-size: $default-md;
            font-family: $default-text;
            font-weight: bold;
        }
    }
}
.onBoarding {

    &-header-col {
        justify-content: space-between;
        align-items: center;

        .btn {
            background-color: $default-button-background;
            border: none;

            &:hover, &:focus, &:active {
                border: none;
                background-color: $default-button-backgroundHover !important;
            }

            i {
                font-size: $default-lg;
            }
        }

        .card-title {
            margin-bottom: $default-sm;
            font-weight: bold;
            font-size: $default-xl;
            font-family: $default-titles;
            text-align: center;
            color: $default-titles-tertiary;
        }

        .onBoarding-header-logo {
            width: 150px;
        }

        .onBoarding-header-logoMobile {
            display: none;
            width: 40px;
        }
    }

    &-form {
        .card-title {
            font-weight: bold;
            font-size: $default-xl;
            font-family: $default-titles;
            text-align: center;
            color: $default-titles-primary;
        }
        .card-subtitle {
            font-weight: bold;
            font-size: $default-lg;
            font-family: $default-titles;
            text-align: center;
            color: $default-titles-primary;
        }

        .card-subtitle[title="Autorización OTP"] {
            margin: $default-sm;
            font-size: $default-md;
            font-family: $default-text;
            text-align: justify;
            color: $default-text-primary;
        }

        .divider-title {
            margin: 20px auto;
            width: 10%;
            height: 3px;
            background-color: $default-border-selected;
        }

        .formAlert {
            display: block;
            font-family: $default-text;
            font-size: $default-md;
            font-weight: bold;
            text-align: center;
            color: $default-alert;
        }

        .bg-info {
            position: absolute;
            padding: 0;
            padding-left: 6px !important;
            right: 22px;
            width: 20px;
            height: 20px !important;
            border-radius: 100%;
            margin-top: -39px !important;
            background-color: $default-button-backgroundSolid !important;
            color: $default-button-text;

            a {
                color: $default-button-text;

                &:hover {
                    color: $default-button-text;
                }
            }
        }

        .multi-person-render{           
            padding: 30px;
            border-radius: $default-xs;
            box-shadow: rgba(116, 110, 110, 0.1) 0px 10px 50px;
        }

        .location {
            position: absolute;
            width: 0 !important;
            height: 0 !important;
        }
        
    }

    &-whatsapp-btn {
        width: 50px;
        height: 50px;
        position: fixed;
        right: 50px;
        bottom: 5vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: $default-lg;
        cursor: pointer;
        transition: .1s;

        &:hover {
            width: 120px;
            right: 45px;
            transition: .1s;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));

            span {
                display: block;
            }
        }

        span {
            display: none;
            font-weight: bold;
            font-family: $default-titles;
            margin-right: $default-md;
        }

        .onBoarding-whatsapp-icon {
            font-size: 30px;
        }
    }

    &-buttons-content {
        position: relative;
    }

    &-buttons {
        position: relative;
    }

    &-documents {
        .onBoarding-documents-text {
            margin: $default-md;
            font-family: $default-text;
            font-size: $default-md;
            text-align: center;
            color: $default-text-secondary;
        }

        .divider-title {
            margin: 20px auto;
            width: 10%;
            height: 3px;
            background-color: $default-border-selected;
        }

        .card {
            box-shadow: none;
        }

        .card-body {
            margin: 0 auto;
            width: 65%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card-title {
                margin: 0;
                margin-left: $default-sm;
                font-weight: 700;
                font-size: 16px;
                color: $default-text-primary;
            }

            .card-text {
                margin: 0;
                margin-left: 10px;
                font-weight: 500;
                font-size: 16px;
                color:  $default-text-primary;
            }

            button {
                margin-top: 0;
            }

            .uploadBtn {
                font-size: $default-md;
                font-family: $default-titles;
                background-color: $default-button-backgroundSolid;
                border: none;

                &:active, &:hover, &:focus {
                    background-color: $default-button-backgroundHover;
                }
            }

            .deleteBtn {
                font-size: $default-md;
                font-family: $default-titles;
                background-color: $default-alert;
                border: none;
            }
        }
    }

    &-button {
        padding: 0;
        margin: $default-lg $default-xs;
        width: 250px;
        font-family: $default-titles;
        font-size: $default-md;
        background: $default-button-background;
        color: $default-button-text;
        border: none;
        border-radius: $default-xs;
        transition: all .5s ease-in-out;

        dt {
            padding: $default-sm;
        }

        &:hover {
            background: $default-button-backgroundHover;
            transition: all .5s ease-in-out;
        }
    }

    &-otp {
        .card-text {
            font-size: $default-md;
            font-family: $default-text;
            color: $default-text-primary;
        }

        .form-label, p {
            font-size: $default-md;
            font-family: $default-text;
            text-align: center;
            color: $default-titles-primary;
        }

        .onBoarding-otp-termnsConditions {

            .form-label a {
                color: $default-titles-primary;
            }

            .custom-control-input:checked ~ .custom-control-label::before {
                background-color: $default-button-backgroundSolid;
                border-color: $default-border-selected;
            }
        }

        .onBoarding-otp-requestNIP {
            font-size: $default-md;
            font-family: $default-titles;
            background-color: transparent;
            color: $default-titles-primary;
            border: none;

            &:hover, &:focus, &:active {
                text-decoration: underline;
                background-color: transparent;
                border: none;
            }
        }
    }

    .suggestion {
        max-width: 1000px;
        width: 100%;
        font-size: $default-md;
        font-family: $default-text;
        color: $default-titles-primary;
        border-color: $default-border-selected;

        &:last-child {
            border-radius: 0 0 $default-xs $default-xs;
        }
    
    }

    .suggestion:first-child {
        margin-top: -3px;
        border-top: 1px solid $default-border-selected !important;
    }

    .onBoarding-address {
        p {
            font-size: $default-md;
            font-family: $default-text;
            text-align: center;
            color: $default-text-primary;
        }
        
        button {
            font-family: $default-titles;
            font-size: $default-md;
            background: $default-button-background;
            color: $default-button-text;
            border: none;
            border-radius: $default-xs;
            transition: all .5s ease-in-out;

            &:hover {
                background: $default-button-backgroundHover;
                transition: all .5s ease-in-out;
            }
        }

    }

    .card {
        padding: 0;
        margin: $default-sm;
        height: 97%;
    }

    .card-header {
        padding: $default-md;
        background-color: $default-background-board;
    }

    .progressBar {
        margin: 25px auto;
        width: 80%;
        border-radius: 10px;

        div {
            padding: 7px 0px;
            background-color: $default-border-selected;
            border-radius: 10px;
        }

    }
    
    .inputDate {
        display: none;
    }
    .inputDatePlaceHolder {
        margin-right: $default-sm;
    }

    .step-form {
        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        .form-group {
            .MuiStack-root {
                padding-top: 0;
                margin-top: -15px;
                overflow: hidden;

                .MuiFormControl-root {
                    width: 100%;
                    min-width: auto;

                    ::before {
                        border-bottom: 1px solid $default-border;
                    }

                    ::after {
                        border-bottom: 1px solid $default-border-selected;
                    }
                }

                .MuiInputBase-input {
                    height: 1.9em;
                    color: $default-titles-primary;
                }

                .MuiIconButton-root {
                    margin-right: 0px;
                }
            }
        }
    }

    .backBtn {
        padding: $default-sm;
        position: relative;
        left: 20%;
    }

    .nextBtn {
        position: absolute;
        top: 0;
        right: 20%;
    }
}

.onBoarding-modal {

    .modal-content {
        background-color: $default-background-primary;
    }

    .modal-header {

        .modal-title {
            font-family: $default-titles;
            font-weight: bold;
            font-size: $default-md;
            color: $default-titles-primary;
        }
    }

    .modal-body {
        p {
            font-size: $default-md;
            font-family: $default-text;
            color: $default-text-primary;
        }

        ul li {
            span {
                font-size: $default-md;
                font-family: $default-text;
                font-weight: bold;
                color: $default-text-primary;
            }

            .onBoarding-item-value {
                color: $default-titles-primary;
            }
        }
    }

    .modal-footer {
        .onBoarding-closeBtn {
            margin-right: $default-sm;
            font-size: $default-md;
            font-family: $default-titles;
            color: $default-button-text;
            background-color: $default-alert;
            border: none;

            &:focus, &:hover, &:active {
                background-color: $default-alert;
            }
        }

        .onBoarding-approvedBtn {
            margin-right: $default-sm;
            font-size: $default-md;
            font-family: $default-titles;
            color: $default-button-text;
            background-color: $default-sucess;
            border: none;

            &:focus, &:hover, &:active {
                background-color: $default-sucess;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .onBoarding {

        &-documents {
            .card-body {
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    .onBoarding {
        &-button {
            width: 100px;
            font-size: 0;

            i {
                font-size: $default-lg;
            }
        }

        .nextBtn  {
            right: 10%;
        }

        .backBtn {
            left: 10%;
        }
    }
}

@media screen and (max-width: 900px) {
    .onBoarding {

        &-documents {
            .card-body {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .onBoarding {

        &-header-col {
            .onBoarding-header-logo {
                display: none;
            }
            .onBoarding-header-logoMobile {
                display: block;
            }
        }

        .card-header {
            padding: $default-md $default-sm;
            background-color: $default-background-board;
        }

        &-documents {
            .card-body {
                display: flex;
                flex-direction: column;

                button {
                    margin-top: $default-md;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .onBoarding {
        &-container {
            padding: 0;
        }

        .card {
            margin: 0;
            height: 100%;
        }

        .progressBar {
            width: 90%;
        }

        &-header-col {
            padding: 0;

            .card-title {
                font-size: $default-lg;
            }
            .btn-primary {
                padding: 0;
            }
        }

        .card-body {
            padding-left: $default-xs !important;
            padding-right:  $default-xs !important;

            .Demo {
                padding: 0;
            }
        }

        &-whatsapp-btn {
            width: 60px;
            right: -15px;

            &:hover {
                width: 140px;
                right: -16px;
            }
        }

        &-form  {
            .card-title {
                font-size: $default-lg;
            }
            .divider-title {
                width: 25%;
            }
            .formAlert {
                font-size: $default-sm;
            }
        }
    }

    .onBoarding-modal {

        .modal-body {
            ul {
                padding-left: $default-xs;
            }
        }

    }
}

.onBoarding-geolocationModal {
    .modal-dialog {
        max-width: 800px;
    }

    div {
        padding: $default-xl $default-md;
    }

    i {
        font-size: $default-xl;
        color: $default-titles-primary;
    }

    h4 {
        font-size: $default-lg;
        font-family: $default-text;
        font-weight: bold;
        color: $default-titles-primary;
    }

    p {
        font-size: $default-md;
        font-family: $default-text;
        color: $default-titles-secondary;

        a {
            font-family: $default-titles;
            font-weight: bold;
            color: $default-titles-primary;
        }
    }

}

.onBoarding-loader-text {
    margin-bottom: $default-sm;
    font-weight: bold;
    font-size: $default-xl;
    font-family: $default-titles;
    text-align: center;
    color: $default-titles-primary;
}

.spinner-border {
    color: $default-border-selected;
}
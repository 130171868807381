.wrap {
    position: relative;
    display: inline-block;
    text-align: center;
    background-color: #f7eeee;
    justify-content: center;
    width: 100%;
    top: 200px;
}
.loader {
    position: relative;
    display: inline-block;
    text-align: center;
    margin: 5px;
    border: 10px solid #ccc6c6;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

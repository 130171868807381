
.approvalCriterial {
    &-header {
        border-bottom: none !important;
        padding-bottom: 0;
    }

    &-headerSection {
        padding-top: 0;
    }

    &-title {
        margin: $default-xs 0;
        padding-top: $default-md;
        width: 100%;
        font-weight: bold;
        font-size: $default-lg;
        font-family: $default-titles;
        text-align: left;
        color: $default-titles-primary;
    }
    &-buttonList {
        padding: $default-sm;
        width: 100%;
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        text-align: left;
        background-color: transparent;
        color: $default-titles-secondary;
        border: none;

        i {
            position: absolute;
            right: 15px;
            font-size: $default-lg;
        }

        &:hover {
            color: $default-titles-primary;
        }
    }

    &-list {
        max-height: 500px;
        position: relative;
        display: none;
        overflow-y: auto;

        p {
            margin-right: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            font-weight: bold;
            color: $default-titles-secondary;
        }

        button {
            padding: revert !important;
            width: 30px !important;
            height: 30px !important;
            color: $default-button-text;
            background-color: $default-button-backgroundSolid;
            border: none !important;

            &:hover, &:active, &:focus {
                color: $default-button-text;
                background-color: $default-button-backgroundSolid !important;
            }
        }
    }

    &-description {
        font-size: $default-md;
        font-family: $default-text;
    }

    &-exitBtn {
        background-color: $default-alert;
        border-color: $default-alert;

        &:hover, &:active, &:focus {
            background-color: $default-alert !important;
            border-color: $default-alert !important;
        }
    }

    &-settingsBtn {
        background-color: $default-sucess;
        border-color: $default-sucess;

        &:hover, &:active, &:focus {
            background-color: $default-sucess !important;
            border-color: $default-sucess !important;
        }
    }

    &-tableTitle {
        margin: $default-lg 0;
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-titles-primary;
    }

    &-saveBtn {
        margin: $default-sm;
        padding: $default-sm;
        width: 200px;
        font-size: $default-md;
        font-family: $default-titles;
        background: $default-button-background;
        color: $default-button-text;
        border: none;
        border-radius: $default-xs;
        transition: all .5s ease-in-out;

        &:hover {
            background: $default-button-backgroundHover;
            transition: all .5s ease-in-out;
        }
    }

    &-label {
        font-size: $default-md;
        font-family: $default-titles;
        color: $default-sucess;
    }

    &-control {
        margin-bottom: $default-xs;
        font-size: $default-md;
        font-family: $default-text;
        color: $default-titles-primary;
        border-bottom: 1px solid $default-border;
        transition: .9ms;

        &:hover {
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        &::placeholder {
            color: $default-text-secondary;
        }
    }

    &-control:focus {
        background: none;
        border-bottom: 1px solid $default-border-selected;
        transition: .9ms;
    }

    &-controlSelect {
        .select__control  {
            border: none;
        }

        .select__control--is-focused {
            box-shadow: none;
        }

        .select__indicator-separator  {
            display: none;
        }
        .select__menu {
            .select__option:hover, .select__option:visited, .select__option:active, .select__option:focus-visible, .select__option:focus-within {
                font-family: $default-text;
                background-color: rgba($color: $default-sucess, $alpha: .4);
            }
        }

        .css-xb97g8:hover {
            color: $default-titles-primary;
        }
    }

    &-footer {
        border-top: none;
    }

    &-modalBody {
        padding-bottom: 0;

        .text-danger {
            margin: 0;
            font-size: $default-sm;
            font-family: $default-text;
            font-weight: bold;
            color: $default-alert;
        }
    }

    &-saveBtnModal {
        margin-left: auto;
    }

    .text-danger {
        margin: 0;
        font-size: $default-sm;
        font-family: $default-text;
        font-weight: bold;
        color: $default-alert;
    }

    .table {
        th {
            text-align: center;
            text-transform: capitalize;
            font-family: $default-titles;
            font-size: $default-md;
            background-color: $default-button-backgroundSolid;
            color: $default-button-text;
        }

        td {

            p {
                font-size: $default-md;
                font-family: $default-text;
            }

            .approvalCriterial-rowBtn {
                width: 35px;
                height: 35px;
                background-color: $default-sucess;
                border-color: $default-border-selected;

                &:hover, &:active, &:focus {
                    background-color: $default-sucess;
                    border-color: $default-border-selected;
                }
            }

            .approvalCriterial-trashBtn {
                width: 35px;
                height: 35px;
                background-color: $default-alert;

                &:hover, &:active, &:focus {
                    background-color: $default-alert;
                }
            }
        }
    }
}
.panel-header {
    padding: $default-xs;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $default-button-backgroundSolid;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);

    .mobile-menu {
        position: absolute;
        left: $default-md;
    }
}

.navbar-collapsed {
    display: none;
}

.custom-navbar {

    .panel-navbar {

        .pcoded-inner-navbar {

            .nav-item {
                font-size: $default-md;
                font-family: $default-text;
                color: $default-titles-secondary;

                .nav-link:hover {
                    color: $default-titles-primary;
                }

                .active {
                    font-weight: bold;
                    color: $default-button-text;
                    background-color: $default-button-backgroundSolid;

                    &:hover {
                        color: $default-button-text;
                    }
                }
            }

            .pcoded-trigger {
                font-weight: bold;
                background: transparent;
                color: $default-titles-primary;
                box-shadow: none;

                .nav-link:first-child {
                    padding-bottom: 0;
                    margin-bottom: 0;

                    &:hover {
                        color: $default-titles-primary;
                    }
                }

                .active {
                    font-weight: bold;
                    background: transparent;
                    color: $default-titles-primary;
                    box-shadow: none;
                }

                .pcoded-submenu {
                    padding-top: 5px;

                    .nav-item {
                        .nav-link {
                            margin: $default-sm $default-md;
                            padding-bottom: $default-xs;
                            font-weight: 400;
                            border-top-color: transparent;
                        }

                        .active {
                            font-weight: bold;
                            color: $default-button-text;
                            background-color: $default-button-backgroundSolid;
                            box-shadow: 0 10px 5px -8px rgb(0 0 0 / 40%);

                            &::before {
                                color: $default-button-text;
                            }
                        }
                    }
                }

            }
        }

    }
}

.panel-whatsapp {
    margin: $default-xl auto !important;
    width: 150px;
    display: flex;
    justify-content: space-between;
    box-shadow: none !important;
    background-color: transparent !important;

    &-btn{
        font-size: $default-md;
        font-family: $default-titles;
        font-weight: 600;
        border-radius: 30px;

        svg {
            font-size: $default-lg;
        }

        &:hover {
            box-shadow: 1px 1px 3px 1px rgba($color: #000000, $alpha: 0.2);
        }
    }
}

.pcoded-wrapper, .pcoded-main-container {
    height: 100%;
    background-color: $default-background-secondary;
}

@media screen and (max-width: 500px) {
    .pcoded-main-container {
        .pcoded-content {
            padding: 0  0 !important;
        }
    }

    .page-wrapper {
        .col {
            padding: 0;
        }
    }
}
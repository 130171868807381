.resetPassword, .newPassword {
    display: grid;
    grid-template-columns: 70% 30%;

    &-hero {
        height: 100vh;
        background-color: $default-background-board;

        .carousel, .carousel-item, .carousel-inner {
            height: 100%;
        }

        .carousel-indicators, .carousel-control-next, .carousel-control-prev {
            display: none;
        }

        .slider-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .6;
        }

    }

    &-container {
        padding: $default-lg;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $default-background-primary;
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .form-group {
            margin-bottom: $default-md;
            width: 100%;
            text-align: center;

            .text-danger {
                position: absolute;
                top: -$default-sm;
                font-size: $default-sm;
                font-family: $default-text;
                font-weight: bold;
                color: $default-alert;
            }
        }

        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }
    }

    &-backbutton i, &-backbutton {
        margin-top: $default-sm;
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        border: none;
        background: none;
        color: $default-info;

        &:hover {
            color: $default-info;
            text-decoration: underline;
        }
    }

    &-logo {
        margin-bottom: $default-xl;
        width: 200px;
    }

    &-title {
        margin: $default-lg 0;
        font-size: $default-lg;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-titles-primary;
    }

    &-subtitle {
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-titles-secondary;
        margin: $default-lg 0;
    }

    &-button {
        width: 250px;
        padding: $default-xs;
        margin: $default-lg 0;
        margin-bottom: $default-sm;
        font-size: $default-md;
        font-family: $default-titles;
        font-weight: bold;
        background: $default-button-background;
        color: $default-button-text;
        border: none;
        border-radius: $default-xs;
        transition: all .2s ease-in-out;

        &:hover {
            width: 270px;
            background: $default-button-backgroundHover;
            transition: all .2s ease-in-out;
        }
    }

    &-text {
        margin-top: $default-xs;
        font-size: $default-md;
        font-family: $default-text;
        color: default-text-secondary;
    }

    &-subtext {
        font-size: $default-md;
        font-family: $default-text;
        font-weight: 600;
        color: default-text-secondary;
    }

    &-buttonNip {
        margin-bottom: $default-sm;
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-text;
        border: none;
        background: none;
        color: $default-info;

        &:hover {
            color: $default-info;
            text-decoration: underline;
        }
    }

    &-success {
        width: 70%;
        margin: 0 auto;

        p {
            margin: $default-xs 0;
            font-family: $default-text;
            font-weight: 600;
            color: $default-text-primary;
        }

        ul {
            margin: $default-xs 0;
            padding: 0;

            li {
                margin: $default-xss;
                text-align: left;
                font-size: $default-sm;
                font-weight: 600;
                font-family: $default-text;
                color: $default-text-primary;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .resetPassword, .newPassword {
        grid-template-columns: 60% 40%;
    }
}

@media screen and (max-width: 800px) {
    .resetPassword, .newPassword {
        grid-template-columns: 100%;
        height: 100%;

        &-hero {
            display: none;
        }
    }
}

@media screen and (max-height: 643px) {
    .newPassword {
        &-hero {
            height: auto;
        }
    }
}

@media screen and (max-height: 402px) {
    .resetPassword {

        &-hero {
            height: auto;
        }
    }
}
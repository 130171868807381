.signup, .signupAdmin, .step0, .step1, .step2 {
    display: grid;
    grid-template-columns: 70% 30%;

    &-hero {
        height: 100vh;
        background-color: $default-background-board;

        .carousel, .carousel-item, .carousel-inner {
            height: 100%;
        }

        .carousel-indicators, .carousel-control-next, .carousel-control-prev {
            display: none;
        }

        .slider-img {
            width: 100%;
            height: 100%;
            opacity: .6;
            object-fit: cover;
        }
    }

    &-container {
        padding: $default-lg;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $default-background-primary;
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-logo {
        width: 200px;
        margin-bottom: $default-xl;
    }

    &-title {
        margin: $default-md 0;
        margin-bottom: $default-xl;
        font-size: $default-lg;
        font-weight: bold;
        font-family: $default-titles;
        text-align: center;
        color: $default-titles-primary;
    }

    &-form {
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .onBoarding-otp-termnsConditions {

            .form-label a {
                color: $default-titles-primary;
                text-align: left;
            }

            .form-label {
                text-align: left;
            }

            .custom-control {
                margin-top: 2px;
            }

            .custom-control-input:checked ~ .custom-control-label::before {
                background-color: $default-button-backgroundSolid;
                border-color: $default-border-selected;
            }

            .text-danger {
                position: absolute;
                top: -$default-lg !important;
                font-size: $default-sm;
                font-family: $default-text;
                font-weight: bold;
                color: $default-alert;
            }
        }

        .form-group {
            margin-bottom: $default-md;
            width: 100%;
            text-align: center;

            .text-danger {
                position: absolute;
                top: -$default-sm;
                font-size: $default-sm;
                font-family: $default-text;
                font-weight: bold;
                color: $default-alert;
            }

            i {
                position: absolute;
                top: $default-md;
                display: none;
                right: $default-md;
                font-size: $default-md;
                color: $default-text-primary;
            }

            .MuiStack-root {
                padding-top: 0;
                margin-top: -9px;
                overflow: hidden;

                .MuiFormControl-root {
                    width: 100%;
                    min-width: auto;

                    ::before {
                        border-bottom: 1px solid $default-border;
                    }

                    ::after {
                        border-bottom: 1px solid $default-border-selected;
                    }
                }

                .MuiInputBase-input {
                    height: 1.9em;
                    color: $default-titles-primary;
                }

                .MuiIconButton-root {
                    margin-right: 0px;
                }
            }
        }

        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            border-bottom: 1px solid $default-border;
            color: $default-titles-primary;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        .form-group-flex {
            width: 100%;
            display: flex;

            .form-group:nth-child(1) {
                margin-right: $default-xs;
            }
        }

        .bg-info {
            position: absolute;
            padding: 0;
            right: 22px;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            margin-top: -39px;
            background-color: $default-button-backgroundSolid !important;
            color: $default-button-text;

            a {
                color: $default-button-text;

                &:hover {
                    color: $default-button-text;
                }
            }
        }

        .inputDate {
            display: none;
        }
        .inputDatePlaceHolder {
            margin-right: $default-sm;
        }
    }

    &-resetPassword {
        margin-top: $default-sm;
        display: block;
        text-align: end;
        font-size: $default-md;
        font-family: $default-text;
        color: $default-info;

        &:hover {
            text-decoration: underline;
        }
    }

    &-button {
        width: 250px;
        padding: $default-sm;
        margin: $default-lg 0;
        font-size: $default-md;
        font-family: $default-titles;
        color: $default-button-text;
        background: $default-button-background;
        border: none;
        border-radius: $default-xs;
        transition: all .2s ease-in-out;

        &:hover, &:active, &:focus {
            width: 270px;
            background: $default-button-backgroundHover;
            transition: all .2s ease-in-out;
        }
    }

    &-text {
        font-size: $default-md;
        font-family: $default-text;
        font-weight: bold;
        color: default-text-primary;
    }

    &-textButton {
        font-size: $default-md;
        font-family: $default-text;
        font-weight: bold;
        color: $default-info;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: $default-info;
        }
    }

    &-buttonSignup {
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-info;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: $default-info;
        }
    }

    &-success {
        width: 70%;
        margin: 0 auto;

        p {
            margin: $default-xs 0;
            font-family: $default-text;
            font-weight: 600;
            color: $default-text-primary;
        }

        ul {
            margin: $default-xs 0;
            padding: 0;

            li {
                margin: $default-xss;
                text-align: left;
                font-size: $default-sm;
                font-weight: 600;
                font-family: $default-text;
                color: $default-text-primary;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .signup {
        grid-template-columns: 60% 40%;
    }
}

@media screen and (max-width: 1200px) {
    .signupAdmin {
        grid-template-columns: 60% 40%;
    }
}

@media screen and (max-width: 800px) {
    .signupAdmin {
        grid-template-columns: 100%;
        height: 100%;

        &-hero {
            display: none;
        }

        &-form {
            .form-group-flex {
                flex-direction: column;

                .form-group:nth-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .signup {
        grid-template-columns: 100%;
        height: 100%;

        &-hero {
            display: none;
        }
        &-form{
            .form-group-flex {
                flex-direction: column;

                .form-group:nth-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-height: 790px) {
    .signupAdmin {
        &-hero {
            height: auto;
        }
    }
}

@media screen and (max-height: 921px) {
    .step2 {
        .signup-hero {
            height: auto;
        }
    }
}

@media screen and (max-height: 636px) {
    .step0 {
        .signup-hero {
            height: auto;
        }
    }
}

@media screen and (max-height: 517px) {
    .step1 {
        .signup-hero {
            height: auto;
        }
    }
}

//Tooltip
.tooltip-inner {
    font-family: $default-text;
    background: $default-button-backgroundSolid !important;
    color: $default-button-text;

    .text-white {
        margin-bottom: $default-xs;
    }
}

.arrow::before {
    border-left-color: $default-button-backgroundSolid  !important;
    border-right-color: $default-button-backgroundSolid  !important;
}


//DatePicker

.react-datepicker {
    font-family: $default-text  !important;
    color: $default-titles-primary  !important;
    background-color: $default-background-primary  !important;

    &-wrapper {
        width: 100%;
    }

    &__triangle {
        left: 190px !important;
        border-bottom-color: $default-background-secondary !important;
    }

    &__header, &__header--custom {
        background-color: $default-background-secondary !important;

        div {
            justify-content: space-between !important;
        }

        button {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: none;
            background-color: $default-button-backgroundSolid;
            color: $default-button-text;
        }

        select {
            border: none;
            border-bottom: 1px solid $default-border-selected;
            background-color: transparent;
            color: $default-titles-secondary;
        }

        option {
            color: $default-titles-primary;
        }
    }

    &__day--selected, &__day--keyboard-selected {
        background-color: $default-button-backgroundSolid !important;
    }
}
.approvalFlow {
    &-docs-header, &-fico-header, &-moral-header, &-sat-header, &-id-header, &-listRisk-header, &-record-header, &-lifeProof-header, &-offer-header, &-schedule-header {
        margin: $default-sm $default-md;

        h5 {
            font-family: $default-titles;
            color: $default-titles-secondary;
        }

        h6 {
            font-family: $default-titles;
            color: $default-titles-secondary;
        }

        span {
            font-family: $default-text;
            font-size: $default-md;
            color: $default-titles-secondary;
        }

        .docs-updateTime, .sat-updateTime, .id-updateTime, .offer-updateTime, .schedule-updateTime {
            margin-top: $default-md;
            display: block;
            color: $default-info !important;
        }

        .moral-alertSuccess, .fico-alertSuccess {
            color: $default-sucess !important;
        }

        .moral-info, .fico-info {
            color: $default-info;
        }

        .fico-bar {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            #gauge-chart6 {
                .text-group {
                    display: none;
                }
            }

            p {
                font-size: $default-lg;
                font-weight: bold;
                text-align: center;
            }
        }
    }

    &-fico-header, &-moral-header, &-offer-header {

        h4 {
            font-family: $default-titles;
            color: $default-titles-secondary;
        }

        p {
            font-family: $default-text;
            font-size: $default-md;
            padding-top: $default-sm;
        }

    }

    &-listRisk-header {
        .listRisk-success {
            padding: $default-lg;
            background-color: rgba($color: $default-sucess, $alpha: .2);
            color: $default-sucess;
        }
        .listRisk-danger {
            padding: $default-lg;
            font-size: $default-lg;
            background-color: rgba($color: $default-alert, $alpha: .2);
            color: $default-alert;
        }

        .listRisk-info {
            font-size: $default-md;
            font-family: $default-text;

            span {
                color: $default-info;
            }
        }
    }


    &-docs-body, &-fico-body, &-moral-body, &-sat-body, &-id-body, &-listRisk-body, &-record-body, &-lifeProof-body, &-offer-body, &-schedule-body {
        margin: $default-sm $default-md;
        padding-top: 0;
        padding-bottom: 0;

        h4, h6, h5 {
            font-family: $default-titles;
            color: $default-titles-secondary;
        }

        table {
            thead {

                th {
                    font-family: $default-titles;
                    font-size: $default-md;
                    text-align: center;
                    text-transform: capitalize;
                    background-color: $default-button-backgroundSolid;
                    color: $default-button-text;

                    button {
                        background-color: transparent;
                        color: $default-button-text;
                        border: none;
                    }
                }
            }

            td {
                font-family: $default-text;
                font-size: $default-md;
                text-align: center;
                color: $default-text-primary;

                .doc-showBtn, .id-tableBtn {
                    background-color: $default-button-backgroundSolid;
                    border-color: $default-button-backgroundSolid;

                    &:active, &:focus, &:hover {
                        background-color: $default-button-backgroundSolid !important;
                        border-color: $default-button-backgroundSolid !important;
                    }
                }

                .switch.switch-info input[type=checkbox]:checked + .cr:before {
                    background-color: $default-button-backgroundSolid;
                }
            }
        }

        .docs-successBtn, .fico-successBtn, .moral-successBtn, .sat-successBtn, .id-successBtn, .record-successBtn, .lifeProof-successBtn, .offer-successBtn {
            font-family: $default-titles;
            font-weight: 600;
            background: $default-sucess;
            border: none;

            &:active, &:focus, &:hover {
                background: $default-sucess !important;
            }
        }

        .docs-alertBtn, .fico-alertBtn, .moral-alertBtn, .sat-alertBtn, .id-alertBtn, .record-alertBtn, .lifeProof-alertBtn, .offer-alertBtn {
            font-family: $default-titles;
            font-weight: 600;
            background: $default-alert;
            border: none;

            &:active, &:focus, &:hover {
                background: $default-alert !important;
            }
        }

        .scheduleCalendar {
            .MuiPickersLayout-root {
                background-color: $default-background-secondary;
                box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);

                .css-1hbyad5-MuiTypography-root {
                    color: $default-titles-primary;
                    font-family: $default-titles;
                    font-weight: bolder;
                    text-transform: capitalize;
                }

                .MuiDialogActions-root {
                    button {
                        color: $default-titles-primary;
                        font-family: $default-titles;
                        font-weight: bolder;
                        text-transform: capitalize;
                    }
                }
                
                .css-156tkzh-MuiButtonBase-root-MuiPickersDay-root, .css-4f0ona-MuiClock-clock {
                    background-color: transparent;
                }

                .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected {
                    color: $default-border-selected;
                }

                .css-d0vs79-MuiClockPointer-root, .css-eg3pzz-MuiClockPointer-thumb {
                    background-color: $default-border-selected;
                }
                
                .css-zddqwy-MuiClockNumber-root, .css-13u1oz-MuiButtonBase-root-MuiButton-root-MuiPickersToolbarButton-root span, .css-1beqopj-MuiPickersYear-yearButton {
                    font-family: $default-titles;
                    font-weight: 500;
                }

                .css-dplwbx-MuiPickersCalendarHeader-label, .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel, .css-156tkzh-MuiButtonBase-root-MuiPickersDay-root {
                    font-family: $default-titles;
                    font-weight: bold;
                }

                .css-1hiwve1-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
                    border: 2px solid $default-border-selected;
                    background: transparent;
                    font-family: $default-titles;
                    font-weight: bold;
                }
                
                .css-156tkzh-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
                    background-color: $default-border-selected !important;
                    font-family: $default-titles;
                    font-weight: bold;
                }
                
                .MuiDialogActions-root {
                    display: none;
                }

                
            }
        }

        .schedule-titles {
            font-family: $default-titles;
            font-weight: bold;
            color: $default-titles-primary;
        }

        .schedule-addressDocs {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {
                font-size: $default-lg;
                margin-right: $default-sm;
            }

            button {
                padding: $default-xs $default-md;
                font-family: $default-titles;
                font-weight: 500;
                font-size: $default-md;
            }
        }
    }

    &-fico-body, &-moral-body, &-sat-body, &-id-body {
        p {
            font-family: $default-text;
            font-size: $default-md;
        }
    }

    &-record-body, &-lifeProof-body {
        h5 {
            font-size: $default-lg !important;
            font-family: $default-titles;
            text-align: center;
            color: $default-titles-primary  !important;
        }

        h6 {
            font-size: $default-md;
            font-family: $default-titles;
            color: $default-titles-secondary;
        }

        .record-text {
            font-size: $default-md;
            color: $default-info;
        }

        .lifeProof-text {
            font-size: $default-md;
            color: $default-info;
        }
    }

    &-sat-body {

        .sat-infoCol {
            ul {
                list-style: none;
                padding-left: 0;

                p {
                    margin: 0;
                    font-weight: bold;
                }
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                border-top: .5px solid $default-border;

                p {
                    &:nth-child(2) {
                        color: $default-text-primary;
                    }
                }
            }

            p, h4 {
                color: $default-info;
            }

            h4 {
                margin: $default-md 0;
            }
        }

    }

    &-docs-footer, &-fico-footer, &-moral-footer, &-sat-footer, &-id-footer, &-record-footer, &-lifeProof-footer, &-offer-footer, &-schedule-footer {
        margin: $default-sm $default-md;
        border-top: none !important;

        h5 {
            font-family: $default-titles;
            color: $default-titles-secondary;
        }

        .docs-successBtn, .fico-successBtn, .moral-successBtn, .sat-successBtn, .id-successBtn, .record-successBtn, .lifeProof-successBtn, .offer-successBtn, .schedule-successBtn {
            font-family: $default-titles;
            font-weight: 600;
            background: $default-sucess;
            border: none;

            &:active, &:focus, &:hover {
                background: $default-sucess !important;
            }
        }

        .docs-alertBtn, .fico-alertBtn, .moral-alertBtn, .sat-alertBtn, .id-alertBtn, .record-alertBtn, .lifeProof-alertBtn, .offer-alertBtn, .schedule-alertBtn {
            font-family: $default-titles;
            font-weight: 600;
            background: $default-alert;
            border: none;

            &:active, &:focus, &:hover {
                background: $default-alert !important;
            }
        }
    }

    &-docsModal-header, &-ficoModal-header, &-moralModal-header, &-satModal-header, &-idModal-header, &-recordModal-header, &-lifeProofModal-header, &-scheduleModal-header  {
        h5 {
            font-family: $default-titles;
            color: $default-titles-secondary;
        }
    }

    &-docsModal-body, &-ficoModal-body, &-moralModal-body, &-satModal-body, &-idModal-body, &-recordModal-body, &-lifeProofModal-body, &-scheduleModal-body {
        p {
            font-family: $default-text;
            font-size: $default-md;
            color: $default-titles-secondary;
            
            span {
                color: $default-titles-primary;
                font-weight: bold;
            }
        }

        .docs-infoBtn {
            font-family: $default-titles;
            font-weight: 600;
            background: $default-info;
            border: none;

            &:active, &:focus, &:hover {
                background: $default-info !important;
            }
        }
    }

    &-fico, &-moral {
        .fico-title, .moral-title {
            padding-top: $default-md;
            font-size: $default-lg;
            font-family: $default-titles;
            color: $default-titles-secondary;
        }
        .fico-subtitle, .moral-subtitle {
            font-size: $default-md;
            font-family: $default-text;
            font-weight: bold;
            color: $default-info;
        }
    }
}
.quoter {
    display: grid;
    grid-template-columns: 70% 30%;

    &-hero {
        position: relative;
        height: 100vh;
        display: flex;
        justify-content: center;
        background-color: $default-background-board;

        .carousel, .carousel-item, .carousel-inner {
            height: 100%;
        }

        .carousel-indicators, .carousel-control-next, .carousel-control-prev {
            display: none;
        }

        .slider-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .6;
        }

        .table-responsive {
            position: absolute;
            top: 10%;
            width: 90%;
            max-height: 80%;
            
            table {
                width: 100%;
                margin-bottom: 0;
                background-color: $default-background-primary;
                opacity: .8;
            }
        }
    }

    &-container {
        padding: $default-lg;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $default-background-primary;
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-form {
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .form-group {
            margin-bottom: $default-md;
            width: 100%;
            text-align: center;

            .text-danger {
                position: absolute;
                top: -$default-sm;
                font-size: $default-sm;
                font-family: $default-text;
                font-weight: bold;
                color: $default-alert;
            }
        }

        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            border-bottom: 1px solid $default-border;
            color: $default-titles-primary;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        .form-group-card {
            margin-bottom: $default-md;

            h3 {
                font-size: $default-md;
                font-family: $default-titles;
                text-align: center;
            }

            .form-group-section {
                margin: $default-md 0;
                display: flex;
                justify-content: space-evenly;

                button {
                    height: 30px;
                    width: 30px;
                    font-size: $default-lg;
                    line-height: 0;
                    background-color: $default-button-backgroundSolid;
                    color: $default-button-text;
                    border: none;
                    border-radius: 100%;
                }
            }
            dt {
                font-size: $default-lg;
                font-weight: bold;
                font-family: $default-titles;
                text-align: center;
                color: $default-titles-primary;
            }

            .rc-slider-rail {
                height: 10px;
                background-color: #D9D9D9;
            }

            .rc-slider-track {
                height: 10px;
                background-color: $default-button-backgroundSolid;
            }
            .rc-slider-handle {
                height: 18px;
                width: 18px;
                background-color: $default-button-backgroundSolid;
                border: none;
            }
        }
    }

    &-logo {
        margin-bottom: $default-md;
        width: 200px;
    }

    &-title {
        margin: $default-md 0;
        font-size: $default-lg;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-titles-primary;
    }

    &-button {
        margin: $default-lg 0;
        width: 250px;
        padding: $default-sm;
        font-size: $default-md;
        font-family: $default-titles;
        font-weight: bold;
        background: $default-button-background;
        color: $default-button-text;
        border: none;
        border-radius: $default-xs;
        transition: all .5s ease-in-out;

        &:hover {
            width: 270px;
            background: $default-button-backgroundHover;
            transition: all .5s ease-in-out;
        }
    }

    &-buttonText {
        margin: $default-sm 0;
        width: 250px;
        padding: 0;
        display: none;
        font-size: $default-md;
        font-family: $default-titles;
        font-weight: bold;
        background: $default-background-primary;
        color: $default-titles-primary;
        border: none;
        border-radius: $default-xs;
        transition: all .5s ease-in-out;

        &:hover {
            width: 270px;
            background: $default-background-primary;
            text-decoration: underline;
            transition: all .5s ease-in-out;
        }
    }

    &-text {
        font-size: $default-md;
        font-family: $default-text;
        text-align: center;
        color: default-text-secondary;
    }

    &-modal-header {

        .modal-title {
            font-family: $default-titles;
            font-weight: bold;
            font-size: $default-md;
            color: $default-titles-primary;
        }

    }
    &-modal-body {
        p {
            font-size: $default-md;
            font-family: $default-text;
            color: $default-text-primary;
        }

        ul li {
            
            font-size: $default-md;
            font-family: $default-text;
            color: $default-text-primary;
        }

        ul li b {
            color: $default-titles-primary;
        }
    }

    &-modal-footer {
        button {
            margin: 0 $default-sm;
            padding: $default-sm;
            font-size: $default-md;
            font-family: $default-titles;
            font-weight: bold;
            background: $default-button-background;
            color: $default-button-text;
            border: none;
            border-radius: $default-xs;
            transition: all .5s ease-in-out;
    
            &:hover, &:active, &:focus {
                background: $default-button-backgroundHover !important;
                transition: all .5s ease-in-out;
            }
        }
    }

    table {
        thead {

            th {
                font-family: $default-titles;
                font-size: $default-md;
                text-align: center;
                text-transform: capitalize;
                background-color: $default-button-backgroundSolid;
                color: $default-button-text;
            }
        }

        td {
            font-family: $default-text;
            font-size: $default-md;
            text-align: center;
            font-weight: bold;
            color: $default-text-primary;
        }
    }


}

@media screen and (max-width: 1200px) {
    .quoter {
        grid-template-columns: 60% 40%;
    }
}

@media screen and (max-width: 800px) {
    .quoter {
        height: 100%;
        grid-template-columns: 100%;

        &-hero {
            display: none;
        }

        .table-responsive {
            max-height: 400px;
        }

        &-buttonText {
            display: block;
        }
    }
}

@media screen and (max-height: 836px) {
    .quoter {
        &-hero {
            height: auto;
        }
    }
}

.rc-slider-tooltip {
    display: none;
    opacity: 0;
}

/* Scroll Bar*/
* {
    scrollbar-width: thin;
    scrollbar-color: $default-border-selected rgba($color: $default-background-primary, $alpha: 0.8);
}

*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: rgba($color: $default-background-primary, $alpha: 0.8);
    opacity: 0.8;
}

*::-webkit-scrollbar-thumb {
    background-color: $default-border-selected;
    border-radius: 20px;
    border: 3px solid rgba($color: $default-background-primary, $alpha: 0.8);
}
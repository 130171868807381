// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff ;
$gray-100: #f8f9fa ;
$gray-200: #e9ecef ;
$gray-300: #dee2e6 ;
$gray-400: #ced4da ;
$gray-500: #adb5bd ;
$gray-600: #6c757d ;
$gray-700: #495057 ;
$gray-800: #343a40 ;
$gray-900: #212529 ;
$black:    #000 ;

$grays: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #1fa2ff ;  // change
$indigo:  #6610f2 ;
$purple:  #6f42c1 ;
$pink:    #e83e8c ;
$red:     #ff5252 ;  // change
$orange:  #fd7e14 ;
$yellow:  #ffba57 ;  // change
$green:   #9ccc65 ;  // change
$teal:    #20c997 ;
$cyan:    #00acc1 ;  // change
$primary-color: #00496d;
$secondary-color: #0086b3;
$third-color: #1fa2ff;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800,
    "primary-color": $primary-color,
    "secondary-color": $secondary-color,
    "third-color":   $third-color
  ),
  $colors
);

$primary:       $primary-color;  // change
$secondary:     $secondary-color;  // change
$third:         $third-color;
$success:       $green;  // change
$info:          $cyan;  // change
$warning:       $yellow;  // change
$danger:        $red;  // change
$light:         $gray-100;  // change
$dark:          $gray-800;  // change

$theme-colors: ();  // change
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "third":  $third,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% ;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  200 ; // change

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 ;
$yiq-text-light:            $white ;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                true ;
$enable-rounded:                              true ;
$enable-shadows:                              false ;
$enable-gradients:                            false ;
$enable-transitions:                          true ;
$enable-prefers-reduced-motion-media-query:   true ;
$enable-hover-media-query:                    false ; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true ;
$enable-pointer-cursor-for-buttons:           true ;
$enable-print-styles:                         true ;
$enable-responsive-font-sizes:                false ;
$enable-validation-icons:                     true ;
$enable-deprecation-messages:                 true ;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem ;
$spacers: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);


// Body
//
// Settings for the `<body>` element.

$body-bg:                   #ecf0f5; // change
$body-color:                #373a3c; // change


// Links
//
// Style anchor elements.

$link-color:                              theme-color("primary"); // change
$link-decoration:                         none ;
$link-hover-color:                        $link-color; // change
$link-hover-decoration:                   underline ;

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% ;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem ;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) ;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) ;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 ;
$grid-gutter-width:           30px ;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 ;
$line-height-sm:              1.5 ;

$border-width:                1px ;
$border-color:                #e3eaef; // change

$border-radius:               0.25rem; // change
$border-radius-lg:            0.25rem; // change
$border-radius-sm:            0.25rem; // change

$rounded-pill:                50rem ;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) ;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) ;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) ;

$component-active-color:      $white ;
$component-active-bg:         theme-color("primary"); // change

$caret-width:                 .3em ;
$caret-vertical-align:        $caret-width * .85 ;
$caret-spacing:               $caret-width * .85 ;

$transition-base:             all .2s ease-in-out ;
$transition-fade:             opacity .15s linear ;
$transition-collapse:         height .35s ease ;

$embed-responsive-aspect-ratios: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  (
    (21 9),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Manrope'; // change
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
$font-family-base:            $font-family-sans-serif ;
// stylelint-enable value-keyword-case

$font-size-base:              1rem ; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 ;
$font-size-sm:                $font-size-base * .875 ;

$font-weight-lighter:         lighter ;
$font-weight-light:           300 ;
$font-weight-normal:          400 ;
$font-weight-bold:            700 ;
$font-weight-bolder:          bolder ;

$font-weight-base:            $font-weight-normal ;
$line-height-base:            1.5 ;

$h1-font-size:                40px; // change
$h2-font-size:                36px; // change
$h3-font-size:                24px; // change
$h4-font-size:                20px; // change
$h5-font-size:                16px; // change
$h6-font-size:                14px; // change

$headings-margin-bottom:      calc($spacer / 2) ;
$headings-font-family:        null ;
$headings-font-weight:        500 ;
$headings-line-height:        1.2 ;
$headings-color:              null ;

$display1-size:               6rem ;
$display2-size:               5.5rem ;
$display3-size:               4.5rem ;
$display4-size:               3.5rem ;

$display1-weight:             300 ;
$display2-weight:             300 ;
$display3-weight:             300 ;
$display4-weight:             300 ;
$display-line-height:         $headings-line-height ;

$lead-font-size:              $font-size-base * 1.25 ;
$lead-font-weight:            300 ;

$small-font-size:             80% ;

$text-muted:                  #868e96 ; // change

$blockquote-small-color:      $gray-600 ;
$blockquote-small-font-size:  $small-font-size ;
$blockquote-font-size:        $font-size-base * 1.25 ;

$hr-border-color:             rgba($black, .1) ;
$hr-border-width:             $border-width ;

$mark-padding:                .2em ;

$dt-font-weight:              $font-weight-bold ;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) ;
$nested-kbd-font-weight:      $font-weight-bold ;

$list-inline-padding:         .5rem ;

$mark-bg:                     #fcf8e3 ;

$hr-margin-y:                 $spacer ;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem ;
$table-cell-padding-sm:       .3rem ;

$table-color:                 $body-color ;
$table-bg:                    null ;
$table-accent-bg:             rgba($black, .05) ;
$table-hover-color:           $table-color ;
$table-hover-bg:              rgba($black, .075) ;
$table-active-bg:             $table-hover-bg ;

$table-border-width:          $border-width ;
$table-border-color:          $border-color ;

$table-head-bg:               $gray-200 ;
$table-head-color:            $gray-700 ;

$table-dark-color:            $white ;
$table-dark-bg:               $gray-800 ;
$table-dark-accent-bg:        rgba($white, .05) ;
$table-dark-hover-color:      $table-dark-color ;
$table-dark-hover-bg:         rgba($white, .075) ;
$table-dark-border-color:     lighten($table-dark-bg, 7.5%) ;
$table-dark-color:            $white ;

$table-striped-order:         odd ;

$table-caption-color:         $text-muted ;

$table-bg-level:              -9 ;
$table-border-level:          -6 ;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .625rem ; // change
$input-btn-padding-x:         1.1875rem ; // change
$input-btn-font-family:       null ;
$input-btn-font-size:         14px ; // change
$input-btn-line-height:       $line-height-base ;

$input-btn-focus-width:       .0rem ; // change
$input-btn-focus-color:       rgba($component-active-bg, .25) ;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color ;

$input-btn-padding-y-sm:      .25rem ;
$input-btn-padding-x-sm:      .5rem ;
$input-btn-font-size-sm:      $font-size-sm ;
$input-btn-line-height-sm:    $line-height-sm ;

$input-btn-padding-y-lg:      .7rem ;
$input-btn-padding-x-lg:      2rem ;
$input-btn-font-size-lg:      $font-size-lg ;
$input-btn-line-height-lg:    $line-height-lg ;

$input-btn-border-width:      $border-width ;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y ;
$btn-padding-x:               $input-btn-padding-x ;
$btn-font-family:             $input-btn-font-family ;
$btn-font-size:               $input-btn-font-size ;
$btn-line-height:             $input-btn-line-height ;

$btn-padding-y-sm:            $input-btn-padding-y-sm ;
$btn-padding-x-sm:            $input-btn-padding-x-sm ;
$btn-font-size-sm:            $input-btn-font-size-sm ;
$btn-line-height-sm:          $input-btn-line-height-sm ;

$btn-padding-y-lg:            $input-btn-padding-y-lg ;
$btn-padding-x-lg:            $input-btn-padding-x-lg ;
$btn-font-size-lg:            $input-btn-font-size-lg ;
$btn-line-height-lg:          $input-btn-line-height-lg ;

$btn-border-width:            $input-btn-border-width ;

$btn-font-weight:             $font-weight-normal ;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) ;
$btn-focus-width:             $input-btn-focus-width ;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow ;
$btn-disabled-opacity:        .65 ;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) ;

$btn-link-disabled-color:     $gray-600 ;

$btn-block-spacing-y:         .5rem ;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           5px ;  // change
$btn-border-radius-lg:        5px ;  // change
$btn-border-radius-sm:        5px ;  // change

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out ;


// Forms

$label-margin-bottom:                   .5rem ;

$input-padding-y:                       $input-btn-padding-y ;
$input-padding-x:                       $input-btn-padding-x ;

$input-font-family:                     $input-btn-font-family ;
$input-font-size:                       $input-btn-font-size ;
$input-font-weight:                     $font-weight-base ;
$input-line-height:                     $input-btn-line-height ;

$input-padding-y-sm:                    $input-btn-padding-y-sm ;
$input-padding-x-sm:                    $input-btn-padding-x-sm ;
$input-font-size-sm:                    $input-btn-font-size-sm ;
$input-line-height-sm:                  $input-btn-line-height-sm ;

$input-padding-y-lg:                    $input-btn-padding-y-lg ;
$input-padding-x-lg:                    $input-btn-padding-x-lg ;
$input-font-size-lg:                    $input-btn-font-size-lg ;
$input-line-height-lg:                  $input-btn-line-height-lg ;

$input-bg:                              $white ;
$input-disabled-bg:                     $gray-200 ;

$input-color:                           $gray-700 ;
$input-border-color:                    $gray-400 ;
$input-border-width:                    $input-btn-border-width ;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) ;

// $input-border-radius:                   $border-radius ;
// $input-border-radius-lg:                $border-radius-lg ;
// $input-border-radius-sm:                $border-radius-sm ;

$input-border-radius:                   0 ;
$input-border-radius-lg:                0 ;
$input-border-radius-sm:                0 ;

$input-focus-bg:                        $input-bg ;
$input-focus-border-color:              lighten($component-active-bg, 25%) ;
$input-focus-color:                     $input-color ;
$input-focus-width:                     $input-btn-focus-width ;
$input-focus-box-shadow:                $input-btn-focus-box-shadow ;

$input-placeholder-color:               $gray-500 ;
$input-plaintext-color:                 $body-color ;

$input-height-border:                   $input-border-width * 2 ;

$input-height-inner:                    calc(#{$input-line-height * 1em} + #{$input-padding-y * 2}) ;
$input-height-inner-half:               calc(#{$input-line-height * .5em} + #{$input-padding-y}) ;
$input-height-inner-quarter:            calc(#{$input-line-height * .25em} + #{calc($input-padding-y / 2)}) ;

$input-height:                          calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}) ;
$input-height-sm:                       calc(#{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border}) ;
$input-height-lg:                       calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border}) ;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out ;

$form-text-margin-top:                  .25rem ;

$form-check-input-gutter:               1.25rem ;
$form-check-input-margin-y:             .3rem ;
$form-check-input-margin-x:             .25rem ;

$form-check-inline-margin-x:            .75rem ;
$form-check-inline-input-margin-x:      .3125rem ;

$form-grid-gutter-width:                10px ;
$form-group-margin-bottom:              1rem ;

$input-group-addon-color:               $input-color ;
$input-group-addon-bg:                  $gray-200 ;
$input-group-addon-border-color:        $input-border-color ;

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out ;

$custom-control-gutter:                 .5rem ;
$custom-control-spacer-x:               1rem ;

$custom-control-indicator-size:         1rem ;
$custom-control-indicator-bg:           $input-bg ;

$custom-control-indicator-bg-size:      50% 50% ;
$custom-control-indicator-box-shadow:   $input-box-shadow ;
$custom-control-indicator-border-color: $gray-500 ;
$custom-control-indicator-border-width: $input-border-width ;

$custom-control-indicator-disabled-bg:          $input-disabled-bg ;
$custom-control-label-disabled-color:           $gray-600 ;

$custom-control-indicator-checked-color:        $component-active-color ;
$custom-control-indicator-checked-bg:           $component-active-bg ;
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5) ;
$custom-control-indicator-checked-box-shadow:   none ;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg ;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow ;
$custom-control-indicator-focus-border-color:   $input-focus-border-color ;

$custom-control-indicator-active-color:         $component-active-color ;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) ;
$custom-control-indicator-active-box-shadow:    none ;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg ;

$custom-checkbox-indicator-border-radius:       $border-radius ;
$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23") ;

$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg ;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color ;
$custom-checkbox-indicator-icon-indeterminate:         str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"), "#", "%23") ;
$custom-checkbox-indicator-indeterminate-box-shadow:   none ;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg ;

$custom-radio-indicator-border-radius:          50% ;
$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"), "#", "%23") ;

$custom-switch-width:                           $custom-control-indicator-size * 1.75 ;
$custom-switch-indicator-border-radius:         calc($custom-control-indicator-size / 2) ;
$custom-switch-indicator-size:                  calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}) ;

$custom-select-padding-y:           $input-padding-y ;
$custom-select-padding-x:           $input-padding-x ;
$custom-select-font-family:         $input-font-family ;
$custom-select-font-size:           $input-font-size ;
$custom-select-height:              $input-height ;
$custom-select-indicator-padding:   1rem ; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight ;
$custom-select-line-height:         $input-line-height ;
$custom-select-color:               $input-color ;
$custom-select-disabled-color:      $gray-600 ;
$custom-select-bg:                  $input-bg ;
$custom-select-disabled-bg:         $gray-200 ;
$custom-select-bg-size:             8px 10px ; // In pixels because image dimensions
$custom-select-indicator-color:     $gray-800 ;
$custom-select-indicator:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"), "#", "%23") ;
$custom-select-background:          $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size ; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: calc((1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x + $custom-select-indicator-padding}) ;
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) ;
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half ;

$custom-select-border-width:        $input-border-width ;
$custom-select-border-color:        $input-border-color ;
$custom-select-border-radius:       $border-radius ;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) ;

$custom-select-focus-border-color:  $input-focus-border-color ;
$custom-select-focus-width:         $input-focus-width ;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color ;

$custom-select-padding-y-sm:        $input-padding-y-sm ;
$custom-select-padding-x-sm:        $input-padding-x-sm ;
$custom-select-font-size-sm:        $input-font-size-sm ;
$custom-select-height-sm:           $input-height-sm ;

$custom-select-padding-y-lg:        $input-padding-y-lg ;
$custom-select-padding-x-lg:        $input-padding-x-lg ;
$custom-select-font-size-lg:        $input-font-size-lg ;
$custom-select-height-lg:           $input-height-lg ;

$custom-range-track-width:          100% ;
$custom-range-track-height:         .5rem ;
$custom-range-track-cursor:         pointer ;
$custom-range-track-bg:             $gray-300 ;
$custom-range-track-border-radius:  1rem ;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) ;

$custom-range-thumb-width:                   1rem ;
$custom-range-thumb-height:                  $custom-range-thumb-width ;
$custom-range-thumb-bg:                      $component-active-bg ;
$custom-range-thumb-border:                  0 ;
$custom-range-thumb-border-radius:           1rem ;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) ;
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow ;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width ; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%) ;
$custom-range-thumb-disabled-bg:             $gray-500 ;

$custom-file-height:                $input-height ;
$custom-file-height-inner:          $input-height-inner ;
$custom-file-focus-border-color:    $input-focus-border-color ;
$custom-file-focus-box-shadow:      $input-focus-box-shadow ;
$custom-file-disabled-bg:           $input-disabled-bg ;

$custom-file-padding-y:             $input-padding-y ;
$custom-file-padding-x:             $input-padding-x ;
$custom-file-line-height:           $input-line-height ;
$custom-file-font-family:           $input-font-family ;
$custom-file-font-weight:           $input-font-weight ;
$custom-file-color:                 $input-color ;
$custom-file-bg:                    $input-bg ;
$custom-file-border-width:          $input-border-width ;
$custom-file-border-color:          $input-border-color ;
$custom-file-border-radius:         $input-border-radius ;
$custom-file-box-shadow:            $input-box-shadow ;
$custom-file-button-color:          $custom-file-color ;
$custom-file-button-bg:             $input-group-addon-bg ;
$custom-file-text: (
  en: "Browse"
) ;


// Form validation

$form-feedback-margin-top:          $form-text-margin-top ;
$form-feedback-font-size:           $small-font-size ;
$form-feedback-valid-color:         theme-color("success") ;
$form-feedback-invalid-color:       theme-color("danger") ;

$form-feedback-icon-valid-color:    $form-feedback-valid-color ;
$form-feedback-icon-valid:          str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23") ;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color ;
$form-feedback-icon-invalid:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23") ;

$form-validation-states: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000 ;
$zindex-sticky:                     1020 ;
$zindex-fixed:                      1030 ;
$zindex-modal-backdrop:             1040 ;
$zindex-modal:                      1050 ;
$zindex-popover:                    1060 ;
$zindex-tooltip:                    1070 ;


// Navs

$nav-link-padding-y:                .5rem ;
$nav-link-padding-x:                1rem ;
$nav-link-disabled-color:           $gray-600 ;

$nav-tabs-border-color:             $gray-300 ;
$nav-tabs-border-width:             $border-width ;
$nav-tabs-border-radius:            $border-radius ;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color ;
$nav-tabs-link-active-color:        $gray-700 ;
$nav-tabs-link-active-bg:           $white;// change
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg ;

$nav-pills-border-radius:           $border-radius ;
$nav-pills-link-active-color:       $component-active-color ;
$nav-pills-link-active-bg:          $component-active-bg; // change

$nav-divider-color:                 $gray-200 ;
$nav-divider-margin-y:              calc($spacer / 2) ;


// Navbar

$navbar-padding-y:                  calc($spacer / 2) ;
$navbar-padding-x:                  $spacer ;

$navbar-nav-link-padding-x:         .5rem ;

$navbar-brand-font-size:            $font-size-lg ;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 ;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base ;
$navbar-brand-padding-y:            calc(($nav-link-height - $navbar-brand-height) / 2 );

$navbar-toggler-padding-y:          .25rem ;
$navbar-toggler-padding-x:          .75rem ;
$navbar-toggler-font-size:          $font-size-lg ;
$navbar-toggler-border-radius:      $btn-border-radius ;

$navbar-dark-color:                 rgba($white, .5) ;
$navbar-dark-hover-color:           rgba($white, .75) ;
$navbar-dark-active-color:          $white ;
$navbar-dark-disabled-color:        rgba($white, .25) ;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") ;
$navbar-dark-toggler-border-color:  rgba($white, .1) ;

$navbar-light-color:                rgba($black, .5) ;
$navbar-light-hover-color:          rgba($black, .7) ;
$navbar-light-active-color:         rgba($black, .9) ;
$navbar-light-disabled-color:       rgba($black, .3) ;
$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") ;
$navbar-light-toggler-border-color: rgba($black, .1) ;

$navbar-light-brand-color:                $navbar-light-active-color ;
$navbar-light-brand-hover-color:          $navbar-light-active-color ;
$navbar-dark-brand-color:                 $navbar-dark-active-color ;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color ;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem ;
$dropdown-padding-y:                .5rem ;
$dropdown-spacer:                   .125rem ;
$dropdown-font-size:                $font-size-base ;
$dropdown-color:                    $body-color ;
$dropdown-bg:                       $white ;
$dropdown-border-color:             rgba($black, .15) ;
$dropdown-border-radius:            $border-radius ;
$dropdown-border-width:             $border-width ;
$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) ;
$dropdown-divider-bg:               $gray-200 ;
$dropdown-divider-margin-y:         $nav-divider-margin-y ;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) ;

$dropdown-link-color:               $gray-900 ;
$dropdown-link-hover-color:         darken($gray-900, 5%) ;
$dropdown-link-hover-bg:            $gray-100 ;

$dropdown-link-active-color:        $component-active-color ;
$dropdown-link-active-bg:           $component-active-bg ;

$dropdown-link-disabled-color:      $gray-600 ;

$dropdown-item-padding-y:           .25rem ;
$dropdown-item-padding-x:           1.5rem ;

$dropdown-header-color:             $gray-600 ;


// Pagination

$pagination-padding-y:              .5rem ;
$pagination-padding-x:              .75rem ;
$pagination-padding-y-sm:           .25rem ;
$pagination-padding-x-sm:           .5rem ;
$pagination-padding-y-lg:           .75rem ;
$pagination-padding-x-lg:           1.5rem ;
$pagination-line-height:            1.25 ;

$pagination-color:                  $link-color; // change
$pagination-bg:                     $white ;
$pagination-border-width:           0 ;// change
$pagination-border-color:           $gray-300 ;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow ;
$pagination-focus-outline:          0 ;

$pagination-hover-color:            $link-hover-color ;
$pagination-hover-bg:               $body-bg; // change
$pagination-hover-border-color:     $gray-300 ;

$pagination-active-color:           $component-active-color ;
$pagination-active-bg:              $component-active-bg; // change
$pagination-active-border-color:    $pagination-active-bg; // change

$pagination-disabled-color:         $gray-600 ;
$pagination-disabled-bg:            $white ;
$pagination-disabled-border-color:  $gray-300 ;


// Jumbotron

$jumbotron-padding:                 2rem ;
$jumbotron-color:                   null ;
$jumbotron-bg:                      $gray-200 ;


// Cards

$card-spacer-y:                     1.25rem; // change
$card-spacer-x:                     1.25rem;  // change
$card-border-width:                 0px; // change
$card-border-radius:                $border-radius ;
$card-border-color:                 rgba($black, .125) ;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) ;
$card-cap-bg:                       rgba($black, 0); // change
$card-cap-color:                    null ;
$card-color:                        null ;
$card-bg:                           $white ;

$card-img-overlay-padding:          1.25rem ;

$card-group-margin:                 calc($grid-gutter-width / 2) ;
$card-deck-margin:                  $card-group-margin ;

$card-columns-count:                3 ;
$card-columns-gap:                  1.25rem ;
$card-columns-margin:               $card-spacer-y ;


// Tooltips

$tooltip-font-size:                 $font-size-sm ;
$tooltip-max-width:                 200px ;
$tooltip-color:                     $white ;
$tooltip-bg:                        $black ;
$tooltip-border-radius:             $border-radius ;
$tooltip-opacity:                   .9 ;
$tooltip-padding-y:                 .25rem ;
$tooltip-padding-x:                 .5rem ;
$tooltip-margin:                    0 ;

$tooltip-arrow-width:               .8rem ;
$tooltip-arrow-height:              .4rem ;
$tooltip-arrow-color:               $tooltip-bg ;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     $tooltip-padding-y ;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x ;
$form-feedback-tooltip-font-size:     $tooltip-font-size ;
$form-feedback-tooltip-line-height:   $line-height-base ;
$form-feedback-tooltip-opacity:       $tooltip-opacity ;
$form-feedback-tooltip-border-radius: $tooltip-border-radius ;


// Popovers

$popover-font-size:                 $font-size-sm ;
$popover-bg:                        $white ;
$popover-max-width:                 276px ;
$popover-border-width:              $border-width ;
$popover-border-color:              rgba($black, .2) ;
$popover-border-radius:             $border-radius-lg ;
$popover-box-shadow:                0 .25rem .5rem rgba($black, .2) ;

$popover-header-bg:                 darken($popover-bg, 3%) ;
$popover-header-color:              $headings-color ;
$popover-header-padding-y:          .5rem ;
$popover-header-padding-x:          .75rem ;

$popover-body-color:                $body-color ;
$popover-body-padding-y:            $popover-header-padding-y ;
$popover-body-padding-x:            $popover-header-padding-x ;

$popover-arrow-width:               1rem ;
$popover-arrow-height:              .5rem ;
$popover-arrow-color:               $popover-bg ;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) ;


// Toasts

$toast-max-width:                   350px ;
$toast-padding-x:                   .75rem ;
$toast-padding-y:                   .25rem ;
$toast-font-size:                   .875rem ;
$toast-color:                       null ;
$toast-background-color:            rgba($white, .85) ;
$toast-border-width:                1px ;
$toast-border-color:                rgba(0, 0, 0, .1) ;
$toast-border-radius:               .25rem ;
$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) ;

$toast-header-color:                $gray-600 ;
$toast-header-background-color:     rgba($white, .85) ;
$toast-header-border-color:         rgba(0, 0, 0, .05) ;


// Badges

$badge-font-size:                   75% ;
$badge-font-weight:                 $font-weight-bold ;
$badge-padding-y:                   .25em ;
$badge-padding-x:                   .4em ;
$badge-border-radius:               $border-radius ;

$badge-transition:                  $btn-transition ;
$badge-focus-width:                 $input-btn-focus-width ;

$badge-pill-padding-x:              .6em ;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem ;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               20px 20px; // change

$modal-dialog-margin:               .5rem ;
$modal-dialog-margin-y-sm-up:       1.75rem ;

$modal-title-line-height:           $line-height-base ;

$modal-content-color:               null ;
$modal-content-bg:                  $white ;
$modal-content-border-color:        rgba($black, .2) ;
$modal-content-border-width:        $border-width ;
$modal-content-border-radius:       $border-radius-sm ;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) ;
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) ;

$modal-backdrop-bg:                 $black ;
$modal-backdrop-opacity:            .5 ;
$modal-header-border-color:         $border-color ;
$modal-footer-border-color:         $modal-header-border-color ;
$modal-header-border-width:         $modal-content-border-width ;
$modal-footer-border-width:         $modal-header-border-width ;
$modal-header-padding-y:            20px; // change
$modal-header-padding-x:            20px; // change
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // change

$modal-xl:                          1140px ;
$modal-lg:                          800px ;
$modal-md:                          500px ;
$modal-sm:                          300px ;

$modal-fade-transform:              scale(0.7);
$modal-show-transform:              none ;
$modal-transition:                  transform .3s ease-out ;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem ;
$alert-padding-x:                   1.25rem ;
$alert-margin-bottom:               1rem ;
$alert-border-radius:               $border-radius ;
$alert-link-font-weight:            $font-weight-bold ;
$alert-border-width:                $border-width ;

$alert-bg-level:                    -10 ;
$alert-border-level:                -9 ;
$alert-color-level:                 6 ;


// Progress bars

$progress-height:                   0.6rem ;  // change
$progress-font-size:                $font-size-base * .50 ;  // change
$progress-bg:                       $body-bg; // change
$progress-border-radius:            $border-radius ;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1) ;
$progress-bar-color:                $white ;
$progress-bar-bg:                   theme-color("primary"); // change
$progress-bar-animation-timing:     1s linear infinite ;
$progress-bar-transition:           width .6s ease ;


// List group

$list-group-color:                  null ;
$list-group-bg:                     $white ;
$list-group-border-color:           rgba($black, .125) ;
$list-group-border-width:           $border-width ;
$list-group-border-radius:          $border-radius ;

$list-group-item-padding-y:         .75rem ;
$list-group-item-padding-x:         1.25rem ;

$list-group-hover-bg:               $gray-100 ;
$list-group-active-color:           $component-active-color ;
$list-group-active-bg:              $component-active-bg ;
$list-group-active-border-color:    $list-group-active-bg ;

$list-group-disabled-color:         $gray-600 ;
$list-group-disabled-bg:            $list-group-bg ;

$list-group-action-color:           $gray-700 ;
$list-group-action-hover-color:     $list-group-action-color ;

$list-group-action-active-color:    $body-color ;
$list-group-action-active-bg:       $gray-200 ;


// Image thumbnails

$thumbnail-padding:                 .25rem ;
$thumbnail-bg:                      $body-bg ;
$thumbnail-border-width:            $border-width ;
$thumbnail-border-color:            $gray-300 ;
$thumbnail-border-radius:           $border-radius ;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075) ;


// Figures

$figure-caption-font-size:          90% ;
$figure-caption-color:              $gray-600 ;


// Breadcrumbs

$breadcrumb-padding-y:              .75rem ;
$breadcrumb-padding-x:              1rem ;
$breadcrumb-item-padding:           .5rem ;

$breadcrumb-margin-bottom:          1rem ;

$breadcrumb-bg:                     $body-bg; // change
$breadcrumb-divider-color:          $gray-600 ;
$breadcrumb-active-color:           $gray-600 ;
$breadcrumb-divider:                quote("/") ;

$breadcrumb-border-radius:          $border-radius ;


// Carousel

$carousel-control-color:             $white ;
$carousel-control-width:             15% ;
$carousel-control-opacity:           .5 ;
$carousel-control-hover-opacity:     .9 ;
$carousel-control-transition:        opacity .15s ease ;

$carousel-indicator-width:           30px ;
$carousel-indicator-height:          3px ;
$carousel-indicator-hit-area-height: 10px ;
$carousel-indicator-spacer:          3px ;
$carousel-indicator-active-bg:       $white ;
$carousel-indicator-transition:      opacity .6s ease ;

$carousel-caption-width:             70% ;
$carousel-caption-color:             $white ;

$carousel-control-icon-width:        20px ;

$carousel-control-prev-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"), "#", "%23") ;
$carousel-control-next-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"), "#", "%23") ;

$carousel-transition-duration:       .6s ;
$carousel-transition:                transform $carousel-transition-duration ease-in-out ; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Spinners

$spinner-width:         2rem ;
$spinner-height:        $spinner-width ;
$spinner-border-width:  .25em ;

$spinner-width-sm:        1rem ;
$spinner-height-sm:       $spinner-width-sm ;
$spinner-border-width-sm: .2em ;


// Close

$close-font-size:                   $font-size-base * 1.5 ;
$close-font-weight:                 $font-weight-bold ;
$close-color:                       $black ;
$close-text-shadow:                 0 1px 0 $white ;


// Code

$code-font-size:                    87.5% ;
$code-color:                        $pink ;

$kbd-padding-y:                     .2rem ;
$kbd-padding-x:                     .4rem ;
$kbd-font-size:                     $code-font-size ;
$kbd-color:                         $white ;
$kbd-bg:                            $gray-900 ;

$pre-color:                         $gray-900 ;
$pre-scrollable-max-height:         340px ;


// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex ;
$overflows: auto, hidden ;
$positions: static, relative, absolute, fixed, sticky ;


// Printing

$print-page-size:                   a3 ;
$print-body-min-width:              map-get($grid-breakpoints, "lg") ;


// Custom Onboarding

// Palette Colors
$default-titles-primary:#1274E7;
$default-titles-secondary: #272733;
$default-titles-tertiary: #F4F5F6;


$default-text-primary: #495057;
$default-text-secondary: #7D7D84;
$default-text-tertiary: #F4F5F6;


$default-alert: #EA5756;
$default-warning: #F79E1B;
$default-sucess: #1274E7;
$default-info: #4B7BE5;

$default-background-primary: #FEFFFE;
$default-background-secondary: #F4F5F6;
$default-background-tertiary: #D5D5D6;
$default-background-board: #4B7BE5;


$default-button-background: #4B7BE5;
$default-button-backgroundSolid: #4B7BE5;
$default-button-text: #FEFFFE;
$default-button-backgroundHover: #1274E7;

$default-border-selected: #4B7BE5;
$default-border: #4B7BE5;

// Sizes
$default-xss: 4px;
$default-xs: 8px;
$default-sm: 12px;
$default-md: 16px;
$default-lg: 24px;
$default-xl: 32px;

// Font
$default-text: 'Manrope', sans-serif;
$default-titles: 'Poppins', sans-serif;

.flow {

    &-header {
        border-bottom: none !important;

        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        .switch.switch-info input[type=checkbox]:checked+.cr:before {
            background: $default-button-backgroundSolid;
        }

        .switch.switch-info input[type=checkbox] {
            width: auto;
        }
    }

    &-header-item {
        width: 100%;
    }

    &-title {
        margin: $default-md 0;
        padding-top: $default-md;
        width: 100%;
        font-weight: bold;
        font-size: $default-lg;
        font-family: $default-titles;
        text-align: center;
        color: $default-titles-primary;
    }

    &-name {
        margin: auto;
        width: 70%;
        display: flex;
        flex-direction: column;

        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }
    }

    &-item {
        margin-bottom: $default-md;
        width: 100%;
        display: flex;
        place-content: center;

        button {
            margin: $default-sm;
            padding: $default-sm;
            width: fit-content;
            font-size: $default-md;
            font-family: $default-titles;
            background: $default-button-background;
            color: $default-button-text;
            border: none;
            border-radius: $default-xs;
            transition: all .5s ease-in-out;

            &:hover {
                background: $default-button-backgroundHover;
                transition: all .5s ease-in-out;
            }
        }
        button:nth-child(2) {
            background: $default-alert;
            transition: all .5s ease-in-out;

            &:hover {
                transition: all .5s ease-in-out;
                background: #ff5252;
            }
        }
    }

    &-text {
        font-size: $default-md;
        font-family: $default-text;
        color: $default-titles-secondary;
    }

    &-step-card {
        background-color: $default-background-primary;

        .card-header {
            padding: $default-md $default-xs;
            padding-bottom: 0;
            border-bottom: none;
        }

        .card-body {
            padding: $default-md $default-xs;
            padding-top: 0;
        }

        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        .switch.switch-info input[type=checkbox]:checked+.cr:before {
            background: $default-button-backgroundSolid;
        }

        .switch.switch-info input[type=checkbox] {
            width: auto;
        }

        .input-title {
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-secondary;
        }

        .input-subtitle {
            font-size: $default-sm;
            font-family: $default-text;
            color: $default-titles-secondary;
        }

        .btn.btn-icon {
            width: 30px;
            height: 30px;
        }

        .action-buttons {
            .arrow {
                background-color: $default-button-backgroundSolid;
                border: none;

                &:hover, &:active, &:focus {
                    background-color: $default-button-backgroundSolid;
                }
            }

            .btn.btn-icon {
                width: 35px;
                height: 35px;
            }
        }

        .table-input {
            margin: $default-lg 0;
            font-size: $default-md;
            font-family: $default-text;
            text-align: center;
        }

        .label-title {
            margin: $default-lg 0;
            font-size: $default-md;
            font-family: $default-titles;
            text-align: center;
        }

        .table {
            th {
                text-align: center;
                text-transform: capitalize;
                font-family: $default-titles;
                font-size: $default-md;
                background-color: $default-button-backgroundSolid;
                color: $default-button-text;
            }

            td {
                background: $default-background-secondary;
                text-align: center;

                p {
                    font-size: $default-md;
                    font-family: $default-text;
                }

                .custom-control-input:checked~.custom-control-label::before {
                    background-color: $default-button-backgroundSolid;
                    border-color: $default-button-backgroundSolid;
                }

                .custom-control-input:not(:disabled):active~.custom-control-label::before {
                    background-color: $default-button-backgroundSolid;
                    border-color: $default-button-backgroundSolid;
                }

                .action-btns {
                    width: 70%;
                    margin: auto;

                    .arrow {
                        background-color: $default-button-backgroundSolid;
                        border: none;

                        &:hover,&:active,&:focus {
                            background-color: $default-button-backgroundSolid;
                        }
                    }
                }
            }
        }
    }

    &-card-header {
        padding: $default-md;
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr;
    }

    .addStep-btn {
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-button-text;
        background: $default-button-backgroundSolid  !important;
        border: none;
        transition: all .2s ease-in-out;

        &:hover, &:active, &:focus {
            background: $default-button-backgroundSolid  !important;
            border: none !important;
            box-shadow: 0 5px 11px 0px rgb(69 90 100 / 30%);
            transition: all .2s ease-in-out;
        }
    }

    .addStep-section {
        align-items: flex-end;
    }
}

.request-info {
    position: sticky;

    &-title {
        width: 100%;
        font-weight: bold;
        font-size: $default-lg;
        font-family: $default-titles;
        text-align: center;
        color: $default-titles-primary;
    }

    button {
        padding: $default-sm;
        width: 100%;
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        text-align: left;
        background-color: transparent;
        color: $default-titles-secondary;
        border: none;

        i {
            position: absolute;
            right: 15px;
            font-size: $default-lg;
        }

        &:hover {
            color: $default-titles-primary;
        }
    }

    ul {
        max-height: 500px;
        position: relative;
        display: none;
        overflow-y: auto;

        p {
            margin-right: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            font-weight: bold;
            color: $default-titles-secondary;
        }

        button {
            padding: revert !important;
            width: 30px !important;
            height: 30px !important;
            color: $default-button-text;
            background-color: $default-button-backgroundSolid;

            &:hover, &:active, &:focus {
                color: $default-button-text;
                background-color: $default-button-backgroundSolid !important;
            }
        }
    }

    ul::-webkit-scrollbar {
        width: .5em;
    }

    ul::-webkit-scrollbar-track {
        background-color: $default-background-secondary;
        border-radius: $default-xs;
    }

    ul::-webkit-scrollbar-thumb {
        background: $default-button-backgroundSolid;
        border-radius: $default-xs;
    }
}

.request-info-fixed {
    top: $default-xs;
}

@media screen and (max-width: 1300px) {
    .request-info-fixed {
        width: 100%;
    }
    .flow {
        &-step-card {
            .table {
                td {
                    .action-btns {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .request-info-fixed {
        width: 100%;
    }

    .flow {
        &-step-card {
            .table {
                td {
                    .action-btns {
                        width: 100%;
                    }
                }
            }
        }

        &-header {
            flex-direction: column !important;
            padding-bottom: 0;
        }

        &-card-header {
            display: flex;
            flex-direction: column;

            .action-buttons {
                order: -1;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .request-info-fixed {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .request-info-fixed {
        width: 100%;
    }
}
.prospects {
    &-header {
        padding: $default-sm;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-show {
        position: relative;
        margin-left: auto;
        margin-right: $default-sm;

        button {
            font-size: $default-md;
            font-family: $default-text;
            background-color: transparent;
            color: $default-text-primary;
            border: none;

            &:hover {
                text-decoration: underline;
                color: $default-titles-primary;
            }
        }
    }

    &-show:hover &-show-card {
        display: block;
    }

    &-show-card {
        padding: $default-md $default-lg;
        width: 200px;
        display: none;
        position: absolute;
        right: 0;
        background-color: $default-background-primary;
        box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
        z-index: 2;
    }

    &-show-item {
        padding: $default-xss;

        input {
            margin-right: $default-xs;
        }
        label {
            font-size: $default-md;
            font-family: $default-text;
        }
    }

    &-title {
        font-size: $default-lg;
        font-family: $default-titles;
        color: $default-titles-secondary;
    }


    &-table {

        thead {

            th {
                font-family: $default-titles;
                font-size: $default-md;
                text-align: left;
                text-transform: capitalize;
                background-color: $default-button-backgroundSolid;
                color: $default-button-text;

                button {
                    background-color: transparent;
                    color: $default-button-text;
                    border: none;
                }
            }
        }

        .table-name {
            i {
                margin-left: $default-xss;
                cursor: pointer;

                &:hover {
                    color: $default-titles-primary;
                }
            }
        }

        td {
            text-align: left;
            font-family: $default-text;
            font-size: $default-md;
            color: $default-text-primary;

            h3 {
                font-family: $default-text;
                font-size: $default-md;
                color: $default-text-primary;
            }

            p {
                margin: 0;
                font-family: $default-text;
                font-size: $default-md;
                color: $default-text-primary;
            }

            a {
                color: $default-titles-primary;

                &:hover {
                    text-decoration: underline;
                }
            }

            button {
                margin-top: $default-xs;
                padding: $default-xss $default-md;
                font-family: $default-text;
                font-weight: bold;
                color: $default-button-text;
                background: $default-button-background;
                border: none;
                border-radius: $default-sm;
                transition: .5s;

                &:hover {
                    text-decoration: underline;
                    background: $default-button-backgroundHover;
                    transition: .5s;
                }
            }

            .tooltip-status {

                i {
                    margin-left: $default-xss;
                    font-size: $default-md;
                }

                .tooltip-statusDescription {
                    padding: $default-xs;
                    position: absolute;
                    right: $default-md;
                    width: 200px;
                    height: auto;
                    display: none;
                    white-space: normal;
                    background-color: rgba($color: $default-button-backgroundSolid, $alpha: .9);
                    border-radius: $default-xs;
                    z-index: 99999;
                    h5 {
                        font-size: $default-sm;
                        font-family: $default-text;
                        color: $default-button-text;
                    }
                }

                &:hover {
                    .tooltip-statusDescription {
                        display: block;
                    }
                }
            }
        }
    }

    &-search {
        position: relative;
        margin-right: $default-sm;

        &:hover {
            i {
                color: $default-titles-primary;
            }
        }

        .form-control {
            margin-bottom: 0;
            width: fit-content;
            font-size: $default-md;
            font-family: $default-text;
            border-bottom: 1px solid $default-border;
            color: $default-titles-primary;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        i {
            position: absolute;
            right: 2px;
            top: 20px;
            font-size: $default-md;
            color: $default-text-primary;
            cursor: pointer;
        }


    }

    .notFoundSearch {
        text-align: center;
        font-size: $default-lg;
        font-weight: 600;
        font-family: $default-text;
    }

    .pagination {

        button {
            margin: $default-xss;
            font-size: $default-md;
            background-color: $default-button-backgroundSolid;
            color: $default-button-text;
            border: none;
            border-radius: $default-xss;
        }

        .activePage {
            margin: $default-xss;
            font-size: $default-md;
            background-color: transparent;
            color: $default-titles-primary;
            border: 1px solid $default-border-selected;
            border-radius: $default-xss;
        }

        .btn-toolbar {
            justify-content: flex-end;
        }

        label {
            font-family: $default-text;
        }

        .form-control {
            margin-bottom: 0;
            width: fit-content;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }
    }
}
.listFlows {
    &-header {
        padding: $default-sm;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &-title {
        font-size: $default-lg;
        font-family: $default-titles;
        color: $default-titles-secondary;
    }
    &-show {
        margin-left: auto;
        margin-right: $default-sm;
        position: relative;

        button {
            font-size: $default-md;
            font-family: $default-text;
            background-color: transparent;
            color: $default-text-primary;
            border: none;

            &:hover {
                text-decoration: underline;
                color: $default-titles-primary;
            }
        }
    }
    &-show:hover {
        display: block;
    }

    .listFlows-table {

        th {
            font-family: $default-titles;
            font-size: $default-md;
            text-align: left;
            text-transform: capitalize;
            background-color: $default-button-backgroundSolid;
            color: $default-button-text;

            button {
                background-color: transparent;
                color: $default-button-text;
                border: none;
            }
        }
        .table-name {
            i {
                margin-left: $default-xss;
                cursor: pointer;

                &:hover {
                    color: $default-titles-primary;
                }
            }
        }

        td {
            font-family: $default-text;
            font-size: $default-md;
            color: $default-text-primary;
            text-align: left;

            h3 {
                font-family: $default-text;
                font-size: $default-md;
                color: $default-text-primary;
            }

            p {
                margin: 0;
                font-family: $default-text;
                font-size: $default-md;
                color: $default-text-primary;
            }

            a {
                color: $default-titles-primary;

                &:hover {
                    text-decoration: underline;
                }
            }

            button {
                margin: $default-xs;
                padding: $default-xss $default-md;
                width: 35px;
                height: 35px;
                font-family: $default-text;
                font-weight: bold;
                color: $default-button-text;
                border: none;
                border-radius: $default-sm;
                transition: .5s;

                &:hover {
                    background: $default-button-backgroundHover;
                    transition: .5s;
                }

                a {
                    color: $default-button-text;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &-search {
        position: relative;
        margin-right: $default-sm;

        .form-control {
            width: fit-content;
            margin-bottom: 0;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }

        i {
            position: absolute;
            right: 2px;
            top: 20px;
            font-size: $default-md;
            color: $default-text-primary;
            cursor: pointer;
        }

        &:hover {
            i {
                color: $default-titles-primary;
            }
        }
    }

    .notFoundSearch {
        font-size: $default-lg;
        font-weight: 600;
        font-family: $default-text;
        text-align: center;
    }

    .pagination {

        button {
            margin: $default-xss;
            font-size: $default-md;
            background-color: $default-button-backgroundSolid;
            color: $default-button-text;
            border: none;
            border-radius: $default-xss;
        }

        .activePage {
            margin: $default-xss;
            font-size: $default-md;
            background-color: transparent;
            color: $default-titles-primary;
            border: 1px solid $default-border-selected;
            border-radius: $default-xss;
        }

        .btn-toolbar {
            justify-content: flex-end;
        }

        label {
            font-family: $default-text;
        }

        .form-control {
            margin-bottom: 0;
            width: fit-content;
            font-size: $default-md;
            font-family: $default-text;
            color: $default-titles-primary;
            border-bottom: 1px solid $default-border;
            transition: .9ms;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .9ms;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .9ms;
        }
    }
}
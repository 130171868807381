.login, .loginAdmin  {
    display: grid;
    grid-template-columns: 70% 30%;

    &-hero {
        height: 100vh;
        background-color: $default-background-board;

        .carousel, .carousel-item, .carousel-inner {
            height: 100%;
        }

        .carousel-indicators, .carousel-control-next, .carousel-control-prev {
            display: none;
        }

        .slider-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .6;
        }

    }

    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $default-lg;
        background-color: $default-background-primary;
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-logo {
        width: 200px;
        margin-bottom: $default-xl;
    }

    &-title {
        margin: $default-lg 0;
        font-size: $default-lg;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-titles-primary;
    }

    &-form {
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .form-group {
            width: 100%;
            margin-bottom: $default-md;
            position: relative;
            text-align: center;

            .text-danger {
                position: absolute;
                top: -$default-sm;
                font-size: $default-sm;
                font-family: $default-text;
                font-weight: bold;
                color: $default-alert;
            }
        }

        .form-control {
            margin-bottom: $default-xs;
            font-size: $default-md;
            font-family: $default-text;
            border-bottom: 1px solid $default-border;
            color: $default-titles-primary;
            transition: .2s;

            &:hover {
                border-bottom: 1px solid $default-border-selected;
                transition: .2s;
            }

            &::placeholder {
                color: $default-text-secondary;
            }
        }

        .form-control:focus {
            background: none;
            border-bottom: 1px solid $default-border-selected;
            transition: .2s;
        }
    }

    &-resetPassword {
        margin-top: $default-sm;
        display: block;
        font-size: $default-md;
        font-family: $default-text;
        color: $default-titles-primary;
        text-align: end;
        transition: all .2s ease-in-out;

        &:hover {
            color: $default-info;
            text-decoration: underline;
            transition: all .2s ease-in-out;
        }
    }

    &-button {
        width: 250px;
        padding: $default-sm;
        margin: $default-lg 0;
        font-size: $default-md;
        font-family: $default-titles;
        border: none;
        border-radius: $default-xs;
        color: $default-button-text;
        background: $default-button-background;
        transition: all .2s ease-in-out;

        &:hover {
            width: 270px;
            background: $default-button-backgroundHover;
            transition: all .2s ease-in-out;
        }
    }

    &-text {
        font-size: $default-md;
        font-family: $default-text;
        color: default-text-secondary;
    }

    &-buttonSignup {
        font-size: $default-md;
        font-weight: bold;
        font-family: $default-titles;
        color: $default-info;
        transition: all .2s ease-in-out;

        &:hover {
            color: $default-info;
            text-decoration: underline;
            transition: all .2s ease-in-out;
        }
    }
}

@media screen and (max-width: 1200px) {
    .login, .loginAdmin {
        grid-template-columns: 60% 40%;
    }
}

@media screen and (max-width: 800px) {
    .login, .loginAdmin {
        height: 100%;
        grid-template-columns: 100%;

        &-hero {
            display: none;
        }
    }
}

@media screen and (max-height: 592px) {
    .loginAdmin {
        &-hero {
            height: auto;
        }
    }
}

@media screen and (max-height: 566px) {
    .login {

        &-hero {
            height: auto;
        }
    }
}